.animation_route {
    // animation-duration: 1s;
    // animation-fill-mode: both;
    animation: fadeRoute 1s ease-out;
}

@keyframes fadeRoute {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}